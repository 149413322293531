import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./styles.css";
import { Button } from "../components/ui/button";
import logo from "../layout/ascendo header logo.png";
import { FaRegUser } from "react-icons/fa";
import { Lightbulb } from "lucide-react";

export default function Header() {
    const navigate = useNavigate();
    const location = useLocation(); // Hook to monitor URL changes

    // State for tenant ID and login status
    const [tenantId, setTenantId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    // Function to get user data from localStorage
    const getUserData = () => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        return userData || null;
    };

    // Function to update the state based on localStorage data
    const updateStateFromStorage = () => {
        const userData = getUserData();
        setTenantId(userData?.tenant_id || null);
        setIsLoggedIn(!!userData);
    };

    // Effect to initialize state and handle URL changes
    useEffect(() => {
        // Update state whenever the URL changes
        updateStateFromStorage();
    }, [location.pathname]); // Runs whenever the pathname changes

    // Handle Logout functionality
    const handleLogout = () => {
        const userData = getUserData();
        const tenantId = userData?.tenant_id;

        // Clear localStorage and update state
        localStorage.removeItem("userData");
        localStorage.removeItem("userInfo");
        updateStateFromStorage();

        // Navigate to login page
        if (tenantId) {
            navigate(`/${tenantId}/Login`);
        } else {
            navigate("/Login");
        }
    };

    return (
        <header className="bg-background border-b border-border flex items-center justify-between px-4 lg:px-6 h-14">
            {tenantId === "64f9f31ab8f2b3a65cbfc48d" ? (
                // Logo and header for tenant_id "64f9f31ab8f2b3a65cbfc48d"
                <img src={logo} alt="Logo" className="h-15 w-auto" />
            ) : tenantId === "6731ec4e94e023f1ce6016b5" ? (
                // Logo and header for tenant_id "6731ec4e94e023f1ce6016b5"
                <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-4">
                        <img
                            src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/images-YspD4MeqP0YzLCBdFOOfNx9zF8teDP.png"
                            alt="CoolRiots Logo"
                            className="rounded-full h-16 w-16 object-contain mr-4"
                        />
                        {/* <div>
                            <h1 className="text-3xl font-bold text-gray-900 dark:text-white flex items-center">
                                AI Teacher
                                <Lightbulb className="ml-2 h-6 w-6 text-yellow-500" />
                            </h1>
                            <h2 className="text-sm text-gray-500 dark:text-gray-400">
                                CoolRiots Academy
                            </h2>
                        </div> */}
                    </div>
                </div>
            ) : (
                // Default logo or behavior when tenant_id doesn't match
                <div className="flex items-center gap-2">
                    <p className="text-gray-500"></p>
                </div>
            )}
            <div>
                <div className="flex items-center gap-2">
                    {isLoggedIn ? (
                        <Button
                            onClick={handleLogout}
                            className="inline-flex items-center justify-center rounded-md bg-gray-50 px-4 py-2 text-sm font-medium text-gray-900 shadow-sm transition-colors hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-[#0b236b] focus:ring-offset-2 dark:bg-gray-900 dark:text-gray-50 dark:hover:bg-gray-800 dark:focus:ring-gray-300"
                        >
                            Logout
                        </Button>
                    ) : (
                        <Button className="inline-flex items-center justify-center rounded-md bg-gray-50 px-4 py-2 text-sm font-medium text-gray-900 shadow-sm transition-colors hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-[#0b236b] focus:ring-offset-2 dark:bg-gray-900 dark:text-gray-50 dark:hover:bg-gray-800 dark:focus:ring-gray-300">
                            &nbsp; <FaRegUser />
                        </Button>
                    )}
                </div>
            </div>
        </header>
    );
}
