
import React, {
  useState,
  useEffect,
  useRef,
  useContext
} from "react"
// import { Sheet, SheetTrigger, SheetContent } from "src/components/ui/sheet"
import { Button } from "src/components/ui/button"
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuItem } from "src/components/ui/dropdown-menu"
import { Card, CardContent } from "src/components/ui/card"

import { Scene } from '@soulmachines/smwebsdk';
import { UserStoryContext } from "src/context/UserStoryState"
import { Avatar, AvatarImage, AvatarFallback } from "src/components/ui/avatar"
import { Textarea } from "src/components/ui/textarea"
import { CartesianGrid, XAxis, Bar, BarChart, Line, LineChart } from "recharts"
import { ChartTooltipContent, ChartTooltip, ChartContainer } from "src/components/ui/chart"

export default function Component() {
  const userData = JSON.parse(localStorage.getItem("userData"));

console.log("HomeuserData",userData)
  const [showAvatarDisplay, setShowAvatarDisplay] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const videoEl = useRef(null);
  const sceneRef = useRef(null); // Using useRef to keep track of the scene instance

  useEffect(() => {
    if (videoEl.current) {
      const apiKey = 'eyJzb3VsSWQiOiJkZG5hLWNvb2xyaW90c2NhMTQtLWJleGZlbyIsImF1dGhTZXJ2ZXIiOiJodHRwczovL2RoLnNvdWxtYWNoaW5lcy5jbG91ZC9hcGkvand0IiwiYXV0aFRva2VuIjoiYXBpa2V5X3YxXzQyMjc3NWQyLWRhYTQtNGQ0Yi04Y2JlLTQ0YjZiMWZhMmFiNCJ9'; // Replace with your actual API key
      const scene = new Scene({
        apiKey: apiKey,
        videoElement: videoEl.current,
        requestedMediaDevices: { microphone: true },
        requiredMediaDevices: { microphone: true },
      });
  
      sceneRef.current = scene;
  
      const onConnectionSuccess = (sessionId) => {
        console.info('success! session id:', sessionId);
  
        // Start video automatically if required
        scene.startVideo()
          .then(videoState => console.info('started video with state:', videoState))
          .catch(error => console.warn('could not start video:', error));
  
        // Activate microphone
        scene.setMediaDeviceActive({ microphone: true })
          .then(() => console.log('Microphone activated'))
          .catch(error => console.error('Error changing media device state:', error));
      };
  
      const onConnectionError = (error) => {
        switch (error.name) {
          case 'noUserMedia':
            console.warn('User blocked device access or devices are not available.');
            break;
          case 'noScene':
          case 'serverConnectionFailed':
            console.warn('Server connection failed.');
            break;
          default:
            console.warn('Unhandled error:', error);
        }
      };
  
      scene.connect()
        .then(sessionId => onConnectionSuccess(sessionId))
        .catch(error => onConnectionError(error));
  
      // Cleanup function to disconnect the scene
      return () => {
        const currentScene = sceneRef.current;
  
        if (currentScene && currentScene.isConnected()) {
          if (typeof currentScene.disconnect === 'function') {
            currentScene.disconnect()
              .then(() => {
                console.log('Disconnected successfully.');
                sceneRef.current = null;  // Clear the reference after disconnect
              })
              .catch(error => console.error('Error during disconnect:', error));
          } else {
            console.warn('disconnect() is not a function on scene.');
          }
        } else {
          console.log('Scene is not connected, skipping disconnect.');
        }
      };
    }
  }, [videoEl.current]);
  
  const widgetContainerRef = useRef(null);

  useEffect(() => {
    // Load the Soul Machines script dynamically
    const script = document.createElement('script');
    script.src = "https://static.soulmachines.com/widget-snippet-1.12.0.min.js";
    script.setAttribute('data-sm-api-key', 'eyJzb3VsSWQiOiJkZG5hLWNvb2xyaW90c2NhMTQtLWJleGZlbyIsImF1dGhTZXJ2ZXIiOiJodHRwczovL2RoLnNvdWxtYWNoaW5lcy5jbG91ZC9hcGkvand0IiwiYXV0aFRva2VuIjoiYXBpa2V5X3YxX2M0YTU4OGExLTA0ZTYtNGJjYy05Mjk0LTJhNmNiNjdiMjY2YyJ9');
    script.async = true;

    // Append the script to the document head
    document.head.appendChild(script);

    // Initialize the Soul Machines widget when the script is loaded
    script.onload = () => {
      if (window.SoulMachines && widgetContainerRef.current) {
        // Initialize the widget
        new window.SoulMachines.Widget({
          containerId: 'soulmachines-widget', // The ID of the container div
          apiKey: 'eyJzb3VsSWQiOiJkZG5hLWNvb2xyaW90c2NhMTQtLWJleGZlbyIsImF1dGhTZXJ2ZXIiOiJodHRwczovL2RoLnNvdWxtYWNoaW5lcy5jbG91ZC9hcGkvand0IiwiYXV0aFRva2VuIjoiYXBpa2V5X3YxX2M0YTU4OGExLTA0ZTYtNGJjYy05Mjk0LTJhNmNiNjdiMjY2YyJ9'
        });
      }
    };

    // Cleanup: Remove the script from the document head on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  
  
  

  return (
    <div className="flex flex-col min-h-dvh">
      {/* <header className="flex items-center justify-between px-4 py-3 bg-primary text-primary-foreground">
        <Sheet>
          <SheetTrigger asChild>
            <Button variant="ghost" size="icon" className="lg:hidden">
              <MenuIcon className="h-6 w-6" />
              <span className="sr-only">Toggle navigation</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left">
            <nav className="grid gap-4 py-6">
              <a href="#" className="flex items-center gap-2 text-lg font-semibold" prefetch={false}>
                <HomeIcon className="h-5 w-5" />
                Home
              </a>
              <a href="#" className="flex items-center gap-2 text-lg font-semibold" prefetch={false}>
                <PackageIcon className="h-5 w-5" />
                Products
              </a>
              <a href="#" className="flex items-center gap-2 text-lg font-semibold" prefetch={false}>
                <InfoIcon className="h-5 w-5" />
                About
              </a>
              <a href="#" className="flex items-center gap-2 text-lg font-semibold" prefetch={false}>
                <MailIcon className="h-5 w-5" />
                Contact
              </a>
            </nav>
          </SheetContent>
        </Sheet>
        <a href="#" className="flex items-center gap-2" prefetch={false}>
          <MountainIcon className="h-6 w-6" />
          <span className="sr-only">Acme Inc</span>
        </a>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon" className="overflow-hidden rounded-full">
              <img
                src="/placeholder.svg"
                width={36}
                height={36}
                alt="Avatar"
                className="overflow-hidden rounded-full"
              />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem>Settings</DropdownMenuItem>
            <DropdownMenuItem>Support</DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem>Logout</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </header> */}
      <main className="flex-1 flex flex-col gap-4 px-4 md:px-6 py-8">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="w-full">
                Dropdown 1
                <ChevronDownIcon className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="w-full">
              <DropdownMenuItem>Option 1</DropdownMenuItem>
              <DropdownMenuItem>Option 2</DropdownMenuItem>
              <DropdownMenuItem>Option 3</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="w-full">
                Dropdown 2
                <ChevronDownIcon className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="w-full">
              <DropdownMenuItem>Option 1</DropdownMenuItem>
              <DropdownMenuItem>Option 2</DropdownMenuItem>
              <DropdownMenuItem>Option 3</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="w-full">
                Dropdown 3
                <ChevronDownIcon className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="w-full">
              <DropdownMenuItem>Option 1</DropdownMenuItem>
              <DropdownMenuItem>Option 2</DropdownMenuItem>
              <DropdownMenuItem>Option 3</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="w-full">
                Dropdown 4
                <ChevronDownIcon className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="w-full">
              <DropdownMenuItem>Option 1</DropdownMenuItem>
              <DropdownMenuItem>Option 2</DropdownMenuItem>
              <DropdownMenuItem>Option 3</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="grid grid-cols-10 gap-4">
      <div className="col-span-6">
        <Card className="w-full">
          <CardContent>
            <h3 className="text-xl font-bold">Card 1</h3>
            <BarchartChart className="aspect-[9/4]" />
          </CardContent>
        </Card>
      </div>
      <div className="col-span-4">
            <Card className="w-full"> 
          <CardContent>
                <h3 className="text-xl font-bold">Card 2</h3>
                <Button onClick={() => setShowAvatarDisplay(!showAvatarDisplay)}>
                  Toggle Avatar/Chat
                </Button>
                {showAvatarDisplay ? (
      //                          <div>
      //             <video ref={videoEl} width="100%" height="100%" id="sm-video"></video>
      // {/* <Button onClick={() => sceneRef.current?.connect()}>Connect</Button>
      // <Button onClick={() => sceneRef.current?.setMediaDeviceActive({ microphone: true, camera: true })}>Activate Both</Button>
      // <Button onClick={() => sceneRef.current?.setMediaDeviceActive({ microphone: false, camera: false })}>Deactivate Both</Button>
      // <Button onClick={() => window.location.reload()}>Reset</Button> */}
      //           </div>
      <div>
        <video
          ref={videoEl}
          id="sm-video"
          width="100%" height="100%"
        ></video>
    </div>
    
    
                    //  <div>
                    // <iframe
                    //   src="https://ddna-coolriotsca14--digital-influencer.soului.dh.soulmachines.cloud/?sig=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjUwMDg2MzgsImlzcyI6InNpZ25lZF91cmwtMzk5ODYwOWQtODRiZC00OTc4LWI5NDctZTc0ZTk5MGM4NzJmIiwiZXhwIjoxODExMzIyMjM4LCJlbWFpbCI6ImNvb2xyaW90c2NhMTQtLWRpZ2l0YWwtaW5mbHVlbmNlckBkZG5hLnN0dWRpbyIsInNvdWxJZCI6ImRkbmEtY29vbHJpb3RzY2ExNC0tZGlnaXRhbC1pbmZsdWVuY2VyIn0.vBJw2LTl0Jm8vknftGCgeCYMGpWyo8GiAqlHUYvruM4"
                    //       title="Avatar Display"
                    //       width="100%"
                    //       height="600px"
                    //   style={{ border: 'none' }}
                    //   allow=" microphone"
                    //   sandbox="allow-scripts allow-same-origin"

                    // />

                    //   </div>
                // <a href="https://ddna-coolriotsca14--bexfeo.soului.dh.soulmachines.cloud/?sig=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjQxMjg0OTEsImlzcyI6InNpZ25lZF91cmwtMDExOWMyMWEtMWFkOS00MjEwLWEwNzYtNjhlYzEzOWRiMGI0IiwiZXhwIjoxODEwNDQyMDkxLCJlbWFpbCI6ImNvb2xyaW90c2NhMTQtLWJleGZlb0BkZG5hLnN0dWRpbyIsInNvdWxJZCI6ImRkbmEtY29vbHJpb3RzY2ExNC0tYmV4ZmVvIn0.7BoEF4ij_uVPC2GNX-ZfOM5acuPi-jP7x44G7oe3G10" target="_blank">Open AI Voice Chat</a>

                ) : (
                  <div className="flex flex-col h-full">
                  <div className="flex-1 overflow-auto">
                    <div className="flex flex-col gap-4 p-4">
                      <div className="flex items-start gap-4">
                        <Avatar className="w-6 h-6 border">
                          <AvatarImage src="/placeholder-user.jpg" />
                          <AvatarFallback>YO</AvatarFallback>
                        </Avatar>
                        <div className="grid gap-1">
                          <div className="font-bold">You</div>
                          <div className="prose text-muted-foreground">
                            <p>
                              Can you explain airplane turbulence to someone who has never flown before? Make it
                              conversational and concise.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-start gap-4">
                        <Avatar className="w-6 h-6 border">
                          <AvatarImage src="/placeholder-user.jpg" />
                          <AvatarFallback>OA</AvatarFallback>
                        </Avatar>
                        <div className="grid gap-1">
                          <div className="font-bold">ChatGPT</div>
                          <div className="prose text-muted-foreground">
                            <p>
                              Of course! Imagine you're in a car driving down a bumpy road, and the ride isn't
                              perfectly smooth. Sometimes, you hit small potholes or bumps, right? Well, when you're
                              in an airplane, it's kind of like that, but in the sky.
                            </p>
                            <p>
                              Airplane turbulence happens when the plane encounters pockets of air that are moving
                              differently. It's like sailing a boat on choppy water. These air pockets can make the
                              plane feel like it's bouncing or shaking a bit. It's completely normal and usually
                              not dangerous at all.
                            </p>
                          </div>
                          <div className="flex items-center gap-2 py-2">
                            <Button
                              variant="ghost"
                              size="icon"
                              className="w-4 h-4 hover:bg-transparent text-stone-400 hover:text-stone-900"
                            >
                              <ClipboardIcon className="w-4 h-4" />
                              <span className="sr-only">Copy</span>
                            </Button>
                            <Button
                              variant="ghost"
                              size="icon"
                              className="w-4 h-4 hover:bg-transparent text-stone-400 hover:text-stone-900"
                            >
                              <ThumbsUpIcon className="w-4 h-4" />
                              <span className="sr-only">Upvote</span>
                            </Button>
                            <Button
                              variant="ghost"
                              size="icon"
                              className="w-4 h-4 hover:bg-transparent text-stone-400 hover:text-stone-900"
                            >
                              <ThumbsDownIcon className="w-4 h-4" />
                              <span className="sr-only">Downvote</span>
                            </Button>
                            <Button
                              variant="ghost"
                              size="icon"
                              className="w-4 h-4 hover:bg-transparent text-stone-400 hover:text-stone-900"
                            >
                              <RefreshCcwIcon className="w-4 h-4" />
                              <span className="sr-only">Regenerate</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-start gap-4">
                        <Avatar className="w-6 h-6 border">
                          <AvatarImage src="/placeholder-user.jpg" />
                          <AvatarFallback>YO</AvatarFallback>
                        </Avatar>
                        <div className="grid gap-1">
                          <div className="font-bold">You</div>
                          <div className="prose text-muted-foreground">
                            <p>
                              That makes sense, thanks for the explanation! I have one more question - is there anything I
                              can do to help minimize the effects of turbulence during a flight?
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-start gap-4">
                        <Avatar className="w-6 h-6 border">
                          <AvatarImage src="/placeholder-user.jpg" />
                          <AvatarFallback>OA</AvatarFallback>
                        </Avatar>
                        <div className="grid gap-1">
                          <div className="font-bold">ChatGPT</div>
                          <div className="prose text-muted-foreground">
                            <p>Great question! Here are a few tips that can help minimize the effects of turbulence:</p>
                            <ul>
                              <li>Stay seated with your seatbelt fastened whenever the seatbelt sign is on.</li>
                              <li>Avoid getting up to use the bathroom or walk around during turbulence.</li>
                              <li>Brace yourself by holding onto the armrests or tray table if you feel sudden drops.</li>
                              <li>
                                Try to stay calm and avoid tensing up, as that can make the experience more uncomfortable.
                              </li>
                              <li>
                                Bring noise-cancelling headphones or earplugs to help block out the sounds of turbulence.
                              </li>
                            </ul>
                            <p>
                              The most important thing is to follow the flight crew's instructions and trust that the
                              plane is designed to withstand turbulence. Try to relax and enjoy the rest of your flight!
                            </p>
                          </div>
                          <div className="flex items-center gap-2 py-2">
                            <Button
                              variant="ghost"
                              size="icon"
                              className="w-4 h-4 hover:bg-transparent text-stone-400 hover:text-stone-900"
                            >
                              <ClipboardIcon className="w-4 h-4" />
                              <span className="sr-only">Copy</span>
                            </Button>
                            <Button
                              variant="ghost"
                              size="icon"
                              className="w-4 h-4 hover:bg-transparent text-stone-400 hover:text-stone-900"
                            >
                              <ThumbsUpIcon className="w-4 h-4" />
                              <span className="sr-only">Upvote</span>
                            </Button>
                            <Button
                              variant="ghost"
                              size="icon"
                              className="w-4 h-4 hover:bg-transparent text-stone-400 hover:text-stone-900"
                            >
                              <ThumbsDownIcon className="w-4 h-4" />
                              <span className="sr-only">Downvote</span>
                            </Button>
                            <Button
                              variant="ghost"
                              size="icon"
                              className="w-4 h-4 hover:bg-transparent text-stone-400 hover:text-stone-900"
                            >
                              <RefreshCcwIcon className="w-4 h-4" />
                              <span className="sr-only">Regenerate</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="max-w-2xl w-full sticky bottom-0 mx-auto py-2 flex flex-col gap-1.5 px-4 bg-background">
                    <div className="relative">
                      <Textarea
                        placeholder="Message ChatGPT..."
                        name="message"
                        id="message"
                        rows={1}
                        className="min-h-[48px] rounded-2xl resize-none p-4 border border-neutral-400 shadow-sm pr-16"
                      />
                      <Button type="submit" size="icon" className="absolute w-8 h-8 top-3 right-3">
                        <ArrowUpIcon className="w-4 h-4" />
                        <span className="sr-only">Send</span>
                      </Button>
                    </div>
                    <p className="text-xs font-medium text-center text-neutral-700">
                      ChatGPT can make mistakes. Consider checking important information.
                    </p>
                  </div>
                  </div>
     
                )}
              </CardContent>
        </Card>
      </div>
    </div>
      </main>
    </div>
  )
}

function ArrowUpIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m5 12 7-7 7 7" />
      <path d="M12 19V5" />
    </svg>
  )
}


function BarchartChart(props) {
  return (
    <div {...props}>
      <ChartContainer
        config={{
          desktop: {
            label: "Desktop",
            color: "hsl(var(--chart-1))",
          },
        }}
        className="min-h-[300px]"
      >
        <BarChart
          accessibilityLayer
          data={[
            { month: "January", desktop: 186 },
            { month: "February", desktop: 305 },
            { month: "March", desktop: 237 },
            { month: "April", desktop: 73 },
            { month: "May", desktop: 209 },
            { month: "June", desktop: 214 },
          ]}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="month"
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tickFormatter={(value) => value.slice(0, 3)}
          />
          <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
          <Bar dataKey="desktop" fill="var(--color-desktop)" radius={8} />
        </BarChart>
      </ChartContainer>
    </div>
  )
}


function ChevronDownIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m6 9 6 6 6-6" />
    </svg>
  )
}


function ClipboardIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
    </svg>
  )
}


function HomeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
      <polyline points="9 22 9 12 15 12 15 22" />
    </svg>
  )
}


function InfoIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M12 16v-4" />
      <path d="M12 8h.01" />
    </svg>
  )
}


function LinechartChart(props) {
  return (
    <div {...props}>
      <ChartContainer
        config={{
          desktop: {
            label: "Desktop",
            color: "hsl(var(--chart-1))",
          },
        }}
      >
        <LineChart
          accessibilityLayer
          data={[
            { month: "January", desktop: 186 },
            { month: "February", desktop: 305 },
            { month: "March", desktop: 237 },
            { month: "April", desktop: 73 },
            { month: "May", desktop: 209 },
            { month: "June", desktop: 214 },
          ]}
          margin={{
            left: 12,
            right: 12,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="month"
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            tickFormatter={(value) => value.slice(0, 3)}
          />
          <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
          <Line dataKey="desktop" type="natural" stroke="var(--color-desktop)" strokeWidth={2} dot={false} />
        </LineChart>
      </ChartContainer>
    </div>
  )
}


function MailIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="16" x="2" y="4" rx="2" />
      <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
    </svg>
  )
}


function MenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  )
}


function MountainIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m8 3 4 8 5-5 5 15H2L8 3z" />
    </svg>
  )
}


function PackageIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m7.5 4.27 9 5.15" />
      <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
      <path d="m3.3 7 8.7 5 8.7-5" />
      <path d="M12 22V12" />
    </svg>
  )
}


function RefreshCcwIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
      <path d="M3 3v5h5" />
      <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
      <path d="M16 16h5v5" />
    </svg>
  )
}


function ThumbsDownIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M17 14V2" />
      <path d="M9 18.12 10 14H4.17a2 2 0 0 1-1.92-2.56l2.33-8A2 2 0 0 1 6.5 2H20a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.76a2 2 0 0 0-1.79 1.11L12 22h0a3.13 3.13 0 0 1-3-3.88Z" />
    </svg>
  )
}


function ThumbsUpIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M7 10v12" />
      <path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" />
    </svg>
  )
}


function XIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  )
}