// import React, { useEffect, useState } from "react";
// import { useLocation, Routes, Route } from "react-router-dom";
// import { UserStoryState } from "src/context/UserStoryState";
// import "./App.css";
// import Home from "src/pages/Home";
// import NotFound from "src/pages/NotFound";
// import OpCodeSearch from "src/pages/OpCodeSearch";
// import OpCodeCreate from "src/pages/OpCodeCreate";
// import OpCodeBulkCreate from "src/pages/OpCodeBulkCreate";
// import OpCodeSurvey from "src/pages/OpCodeSurvey";
// import DynamicImport from "src/pages/UserStory/dynamicImport";
// import Login from 'src/pages/Login/login';
// import Signup from 'src/pages/Signup/signup';
// import Portal from 'src/pages/Portal/portal';
// import Cui from 'src/components/Cui';
// import Avatar from "./components/Avatar";

// function App() {
    

//     return (
//         <UserStoryState>
//             <Routes>
//                 <Route index path="/" element={<Home />} />
//                 <Route path="/Login" element={<Login />} />
//                 <Route path="/Signup" element={<Signup />} />
//                 <Route path="/Home" element={<Portal />} />
//                 <Route path="/cui" element={<Cui />} />
//                 <Route path="/avatar" element={<Avatar />} />
//                 <Route path="/user-story/*" element={<DynamicImport  />} />
//                 <Route path="/op-code-search" element={<OpCodeSearch />} />
//                 <Route path="/op-code-create" element={<OpCodeCreate />} />
//                 <Route path="/op-code-bulk-create" element={<OpCodeBulkCreate />} />
//                 <Route path="/op-code-survey" element={<OpCodeSurvey />} />
//                 <Route path="*" element={<NotFound />} />
//             </Routes>
//         </UserStoryState>
//     );
// }

// export default App;



import React, { Suspense } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { UserStoryState } from "src/context/UserStoryState";
import Home from "src/pages/Home";
import NotFound from "src/pages/NotFound";
import OpCodeSearch from "src/pages/OpCodeSearch";
import OpCodeCreate from "src/pages/OpCodeCreate";
import OpCodeBulkCreate from "src/pages/OpCodeBulkCreate";
import OpCodeSurvey from "src/pages/OpCodeSurvey";
import DynamicImport from "src/pages/UserStory/dynamicImport";
import Portal from "src/pages/Portal/portal";
import Cui from "src/components/Cui";
import Avatar from "./components/Avatar";
import "./App.css";
import ChatPage from 'src/components/chat';
// Component to dynamically load login or signup based on organization
function OrgPage({ page }) {
  const { org } = useParams();

  // Dynamically import the org-specific login or signup component
  const PageComponent = React.lazy(() =>
    import(`src/pages/UserStory/${org}/${page.toLowerCase()}`).catch(() => ({ default: NotFound }))
  );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <PageComponent />
    </Suspense>
  );
}

function App() {
  return (
    <UserStoryState>
      <Routes>
        {/* Static routes remain as they are */}
        <Route index path="/" element={<Home />} />
        <Route path="/home" element={<Portal />} />
        <Route path="/cui" element={<Cui />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/avatar" element={<Avatar />} />
        <Route path="/user-story/*" element={<DynamicImport />} />
        <Route path="/op-code-search" element={<OpCodeSearch />} />
        <Route path="/op-code-create" element={<OpCodeCreate />} />
        <Route path="/op-code-bulk-create" element={<OpCodeBulkCreate />} />
        <Route path="/op-code-survey" element={<OpCodeSurvey />} />
        
        {/* Dynamic routes for login and signup based on organization */}
        <Route path="/:org/signup" element={<OrgPage page="signup" />} />
        <Route path="/:org/login" element={<OrgPage page="login" />} />
        
        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </UserStoryState>
  );
}

export default App;

