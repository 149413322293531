import React, { useContext } from "react";
import styled from "styled-components";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { UserStoryContext } from "src/context/UserStoryState";
// Styled input component
const StyledInput = styled.input`
    border: 0px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 5px 0px;
    background-color: white;

    &:focus {
        outline: none;
        color: black;
        background-color: white;
        border-bottom: 2px solid #b1092a;
    }

    &::placeholder {
        color: black;
    }

    &[type="radio"] {
        & + label {
            color: #000 !important;
        }
    }
`;

// Container for radio buttons
const RadioContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
`;

// Validation functions
const uppercase = (value) => ({ valid: true, value: value.toUpperCase() });
const maxLength = (value, length) => {
    if (value.length > length) {
        return { valid: false, message: `Must be at most ${length} characters` };
    }
    return { valid: true, value };
};
const required = (value) => {
    if (value.trim() === "") {
        return { valid: false, message: "This field is required" };
    }
    return { valid: true, value };
};
const email = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
        return { valid: false, message: "Invalid email address" };
    }
    return { valid: true, value };
};
const futureDateValidation = (value) => {
    const selectedDate = new Date(value);
    const today = new Date();

    // Check if the selected date is valid and not in the future
    if (isNaN(selectedDate.getTime()) || selectedDate > today) {
        return { valid: false, message: "Invalid date or date is in the future" };
    }

    return { valid: true, value };
};
const phoneNumber = (value) => {
    const phoneRegex = /^[89]\d{7}$/;
    if (!phoneRegex.test(value)) {
        return { valid: false, message: "Invalid phone number" };
    }
    return { valid: true, value };
};
const balanceValidation = (value, contextData) => {
    // Find the item in contextData that has a "balance" property
    const balanceItem = contextData.find((data) => data.hasOwnProperty("balance"));
    
    // Check if balanceItem is found
    if (!balanceItem) {
        return { valid: false, message: "Balance information is not available" };
    }
    
    const balance = balanceItem.balance;
    const amount = parseFloat(value);

    // Check if the value is a valid number and not more than the balance
    if (isNaN(amount) || amount > balance) {
        return { valid: false, message: "Insufficient balance or invalid amount" };
    }

    return { valid: true, value };
};

const traineeId = (value, type) => {
console.log("traineeId",value)
console.log("traineeId2",type)
    var nricRegex = /^[ST]\d{6}[0-9][A-Z]$/;
    var finRegex = /^[FGM]\d{6}[0-9][A-Z]$/;
    let pos = "";
    let valid = false;
    if ((type === "SB - Singapore Blue Identification Card" || type === "SP - Singapore Pink Identification Card") && nricRegex.test(value)) {
        var weights = [2, 7, 6, 5, 4, 3, 2];
        var offset = value[0] === "T" ? 4 : 0;
        let sum = offset;
        for (let i = 1; i <= 7; i++) {
            sum += parseInt(value[i]) * weights[i - 1];
        }
        var remainder = sum % 11;
        switch (value[0]) {
            case "S":
            case "T":
                pos = "JZIHGFEDCBA".charAt(remainder);
                break;
        }
        if (value[8] === pos) {
            valid = true;
        }
    } else if (type === "SO - Fin/Work Permit" && finRegex.test(value)) {
        var weights = [2, 7, 6, 5, 4, 3, 2];
        var offset = value[0] === "G" ? 4 : 0;
        let sum = offset;
        for (let i = 1; i <= 7; i++) {
            sum += parseInt(value[i]) * weights[i - 1];
        }
        var remainder = sum % 11;
        switch (value[0]) {
            case "F":
            case "G":
                pos = "XWUTRQPNMLK".charAt(remainder);
                break;
            case "M":
                pos = "TRQPNJLKXWU".charAt(remainder);
                break;
        }
        if (value[8] === pos) {
            valid = true;
        }
    } else if (type === "FP - Foreign Passport" || type === "OT - Others") {
        valid = true;
    }
    if (!valid) {
        return { valid: false, message: "Invalid Trainee ID" };
    }
    return { valid: true, value };
}
// Map validation types to functions
const validationFunctions = {
    uppercase,
    maxLength,
    required,
    email,
    phoneNumber,
    traineeId,
    futureDateValidation,
    balanceValidation
    // Add other validation functions as needed
};

// Apply validations dynamically
const applyValidations = (value, validations,contextData=null) => {
    let validatedValue = value;
    let isValid = true;
    let message = '';
    let validationss = validations.validation ? validations.validation : validations
    let conditionalValue = validations.value ? validations.value : ""

    validationss.forEach((validation) => {

        const validationType = typeof validation === 'string' ? validation : validation.type;
        // const validationValue = validation.value;
        // console.log("B val", validation, validationType, validationValue)
        if (validationFunctions[validationType]) {
           let result
            if(validationType==="balanceValidation"){
                result = validationFunctions[validationType](validatedValue, contextData);
            }else{
                result = validationFunctions[validationType](validatedValue, conditionalValue);
            }
            
            if (!result.valid) {
                isValid = false;
                message = result.message;
            }
            validatedValue = result.value || validatedValue;
        }
    });

    return { validatedValue, isValid, message };
};

// InputField component
export const InputField = (props) => {
    const {
        contextData
      } = useContext(UserStoryContext);
    const { item, formData, dispatch, dynamicValidations } = props;
    const [errorMessage, setErrorMessage] = React.useState('');
    // console.log("D Validation ", dynamicValidations);
    // console.log('iteminput', item);
    if (!item) {
        return null;
    }

    // const handleChange = (e) => {
    //     const { value } = e.target;
    //     const validations = item.validation ? item.validation : dynamicValidations ? dynamicValidations : [];

    //     const { validatedValue, isValid, message } = applyValidations(value, validations);
    //     if (!isValid) {
    //         setErrorMessage(message);
            
    //     } else {
    //         setErrorMessage('');
    //     }
        
    //     dispatch({
    //         type: "SET_FORM_DATA",
    //         payload: {
    //             ...formData,
    //             [item?.key || item?.field]: validatedValue,
    //         },
    //     });
    // };
    const handleChange = (e) => {
        const { value } = e.target;
        // const validations = item.validation ? [...item.validation] : dynamicValidations ? [...dynamicValidations] : [];
        const validations = item.validation ? item.validation : dynamicValidations ? dynamicValidations : [];
        const {  validatedValue,isValid, message } = applyValidations(value, validations,contextData);
            if (!isValid) {
            setErrorMessage(message);
            
        } else {
            setErrorMessage('');
        }
        // Update the specific field value in form data
        const updatedFormData = {
            ...formData,
            [item?.key || item?.field]: validatedValue,
        };
    
        // Update the validation object
        const updatedValidation = {
            ...updatedFormData.validation, // Preserve existing validations
            [item?.key || item?.field]: isValid, // Update the validation status for the current field
        };
    
        // Dispatch the updated form data including the new value and validation object
        dispatch({
            type: "SET_FORM_DATA",
            payload: {
                ...updatedFormData,
                validation: updatedValidation,
            },
        });
    };
    

    
    return (
        <div style={{ width: '100%' }}>
            <div className="space-y-2">
                <Input
                    type={item?.type || item?.inputType}
                    name={item?.key || item?.field}
                    id={item?.key || item?.field}
                    placeholder={item?.placeholder}
                    value={formData[item?.key || item?.field] || ""}
                    className="border-b border-gray-300 focus:border-blue-500 dark:border-gray-600 dark:focus:border-blue-500"
                    onChange={handleChange}
                    style={{ width: '100%' }}
                    readOnly={item?.readonly === true}
                    disabled={item?.disabled === true} 
                />
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </div>
        </div>
    );
};

// RadioField component
export const RadioField = (props) => {
    const { item, formData, dispatch } = props;
    const [errorMessage, setErrorMessage] = React.useState('');

    console.log("Radio Item, ", item);
    if (!item) {
        return null;
    }

    const handleChange = (e) => {
        const { value } = e.target;
        const { validatedValue, isValid, message } = applyValidations(value, item.validation || []);
        if (!isValid) {
            setErrorMessage(message);
        } else {
            setErrorMessage('');
        }
        dispatch({
            type: "SET_FORM_DATA",
            payload: {
                ...formData,
                [item?.key || item?.field]: validatedValue,
            },
        });
    };

    return (
        <RadioContainer
            id={item.key || item.field}
            style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            }}
        >
            {item.values.map((value, index) => (
                <div
                    key={index}
                    className="flex items-center space-x-2"
                >
                    <StyledInput
                        type="radio"
                        id={value}
                        value={value || ""}
                        checked={formData[item?.key || item?.field] === value}
                        onChange={handleChange}
                    />
                    <Label htmlFor={value}>{value}</Label>
                </div>
            ))}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </RadioContainer>
    );
};
