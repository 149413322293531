import React, { createContext, useState } from "react";

export const UserStoryContext = createContext();

export const UserStoryState = ({ children }) => {
    const [contextData, setContextData] = useState([]);
    const [viewDetailRecordID, setViewDetailRecordID] = useState("");
    const [viewDetailTableName, setViewDetailTableName] = useState("");

    console.log("contextData", contextData);

    // Add data to the context
    const AddContextData = (dataToAdd) => {
        console.log("AddContextData",dataToAdd)
        if (typeof dataToAdd !== 'object') {
            console.error("Invalid input. Expected an object.");
            return;
        }

        let updatedContextData = [...contextData];
        const keys = Object.keys(dataToAdd);
        console.log("AddContextData",contextData)
        keys.forEach(key => {
            const index = updatedContextData.findIndex(item => item.hasOwnProperty(key));
            if (index === -1) {
                // If the key doesn't exist, add new key-value pair
                updatedContextData.push({ [key]: dataToAdd[key] });
            } else {
                // If the key exists, update the value
                updatedContextData[index][key] = dataToAdd[key];
            }
        });

        setContextData(updatedContextData);
        console.log("Updated contextData:", updatedContextData);
    };

    // Store user data in the context
    const StoreUserData = (userData) => {
        if (typeof userData !== 'object') {
            console.error("Invalid input. Expected an object.");
            return;
        }

        const existingUserDataIndex = contextData.findIndex(item => item.UserData);

        if (existingUserDataIndex !== -1) {
            // If user data already exists, update it
            const updatedContextData = [...contextData];
            updatedContextData[existingUserDataIndex].UserData = userData;
            setContextData(updatedContextData);
            console.log("User data updated in context:", updatedContextData);
        } else {
            // Otherwise, add new user data
            const updatedContextData = [
                ...contextData,
                { UserData: userData }
            ];
            setContextData(updatedContextData);
            console.log("User data stored in context:", updatedContextData);
        }
    };

    // Remove keys from contextData
    const RemoveContextData = (keysToRemove) => {
        const updatedContextData = contextData.filter(item => {
            return !keysToRemove.some(keyToRemove => {
                return item.hasOwnProperty(keyToRemove);
            });
        });

        // Ensure that the state is fully updated
        setContextData(updatedContextData);
        console.log("Context data after removal:", updatedContextData);
    };

    // Get previous context data
    const GetPreviousContextData = () => {
        return contextData.length > 0 ? contextData : null;
    };

    // Set view details (can be used to store specific record view details)
    const SetViewDetailData = (tableName, RecordID) => {
        console.log(tableName, RecordID);
        setViewDetailTableName(tableName);
        setViewDetailRecordID(RecordID);
    };

    // Reset view details
    const ResetViewDetailData = () => {
        setViewDetailTableName("");
        setViewDetailRecordID("");
    };

    const contextValue = {
        contextData,
        AddContextData,
        StoreUserData,
        RemoveContextData,
        GetPreviousContextData,
        SetViewDetailData,
        ResetViewDetailData,
        viewDetailTableName,
        viewDetailRecordID,
    };

    return (
        <UserStoryContext.Provider value={contextValue}>
            {children}
        </UserStoryContext.Provider>
    );
};
