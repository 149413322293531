import { Link } from 'react-router-dom';
import { Button } from "src/components/ui/button";
import { Card, CardContent } from "src/components/ui/card";
import { BookOpen, Code, Users } from 'lucide-react';

function Home() {
  return (
    <div className="flex flex-col min-h-screen">

      <main className="flex-grow">
        {/* Reduced padding here */}
        <section className="bg-gradient-to-b from-primary to-primary-foreground text-primary-foreground py-2" style={{ maxHeight: '300px' }}
        >
          <div className="container mx-auto text-center">
            <h2 className="text-4xl font-bold mb-2">Welcome to Coolriots Academy</h2>
            <p className="text-xl mb-6">Ignite your learning journey with cutting-edge courses</p>
            <div className="flex justify-center space-x-4">
              <Button asChild>
                <Link to="/64f9f31ab8f2b3a65cbfc48d/Login">Asendo</Link>
              </Button>
              <Button asChild variant="secondary">
                <Link to="/6731ec4e94e023f1ce6016b5/Login">AI Teacher</Link>
              </Button>
              <Button asChild variant="secondary">
                <Link to="/665467d84b7c867c744381a0/Login">Legal Workflow</Link>
              </Button>
            </div>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto">
            <h3 className="text-3xl font-bold text-center mb-12">Why Choose Coolriots Academy?</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <Card>
                <CardContent className="flex flex-col items-center p-6">
                  <BookOpen className="w-12 h-12 mb-4 text-primary" />
                  <h4 className="text-xl font-semibold mb-2">Diverse Courses</h4>
                  <p className="text-center">Explore a wide range of courses tailored to your interests and career goals.</p>
                </CardContent>
              </Card>
              <Card>
                <CardContent className="flex flex-col items-center p-6">
                  <Code className="w-12 h-12 mb-4 text-primary" />
                  <h4 className="text-xl font-semibold mb-2">Hands-on Learning</h4>
                  <p className="text-center">Gain practical skills through interactive projects and real-world applications.</p>
                </CardContent>
              </Card>
              <Card>
                <CardContent className="flex flex-col items-center p-6">
                  <Users className="w-12 h-12 mb-4 text-primary" />
                  <h4 className="text-xl font-semibold mb-2">Expert Instructors</h4>
                  <p className="text-center">Learn from industry professionals and experienced educators.</p>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-muted py-8">
        <div className="container mx-auto text-center">
          <p>&copy; 2023 Coolriots Academy. All rights reserved.</p>
          <div className="mt-4">
            <Link to="/privacy-policy" className="text-primary hover:underline mr-4">Privacy Policy</Link>
            <Link to="/terms-of-service" className="text-primary hover:underline mr-4">Terms of Service</Link>
            <Link to="/contact-us" className="text-primary hover:underline">Contact Us</Link>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
