// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: #f1f1f1;
    position: relative;
}

.footer-container {
    background-color: var(--primary-color);
    color: white;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
}

.body-container {
    margin-top: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/layout/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,sCAAsC;IACtC,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".header-container {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 1rem 2rem;\n    background-color: #f1f1f1;\n    position: relative;\n}\n\n.footer-container {\n    background-color: var(--primary-color);\n    color: white;\n    padding: 1rem 2rem;\n    display: flex;\n    justify-content: space-between;\n}\n\n.body-container {\n    margin-top: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
