/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useReducer, useState } from "react";
import { styled } from "styled-components";
import { httpRequest, httpRequest1, httpRequest2 } from "../utils/httpsRequest";
import { AppLoader } from "./LoaderSpinner";
import { SearchForm } from "./Form";
import { PrimaryButton } from "./Buttons";
import Table from "./Table";
import { Pagination } from "./Pagination";
import Card2 from "./Card";
import Modal from "./Modal";
import OpCodeCreateForm from "./OpCodeCreateForm";
import OpCodeBulkCreateForm from "./OpCodeBulkCreateForm";
import { UserStoryContext } from "../context/UserStoryState";
import { apiConfig } from "../utils/api/config";
import { performApiRequest } from "../utils/apiRequest";
import DownloadCSV from './DownloadCSV';
import { Button } from "./ui/button";
import { Input } from "./ui/input"
import OpCodeTable from "./Table";
import {
    CollapsibleTrigger,
    CollapsibleContent,
    Collapsible,
} from "./ui/collapsible";

const SearchToggleContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #faf9f450;
    padding: 10px;
    border-radius: 15px;
`;

const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px 0;
    width: 100%;
`;

const CreateSearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 20px 0;
    width: 100%;
`;

const initialSearchState = {
    template: {
        templateName: "",
        tableName: "",
        searchComponent: {},
        tableComponent: {},
        OpCodeEditTemplateName: "",
    },
    formData: {},
    currentPage: 1,
    tableData: {},
    totalPage: 0,
    totalFetchedRow: 0,
    totalRows: 0,
    prevFrameData: {},
};

const searchReducer = (state, action) => {
    console.log("action", action);
    switch (action.type) {
        case "SET_TEMPLATE":
            return { ...state, template: action.payload };
        case "SET_FORM_DATA":
            return { ...state, formData: action.payload };
        case "SET_TABLE_DATA":
            return { ...state, tableData: action.payload };
        case "SET_CURRENT_PAGE":
            return { ...state, currentPage: action.payload };
        case "SET_TOTAL_PAGE":
            return { ...state, totalPage: action.payload };
        case "SET_FETCHED_TOTAL_ROW":
            return { ...state, totalFetchedRow: action.payload };
        case "SET_TOTAL_ROWS":
            return { ...state, totalRows: action.payload };
        case "SET_PREVIOUS_CONTEXT_DATA":
            return { ...state, prevFrameData: action.payload };
        default:
            return state;
    }
};
function createNewDictWithKeys(originalDict, keys) {
    return keys.reduce((newDict, key) => {
        if (key in originalDict) {
            newDict[key] = originalDict[key];
        }
        return newDict;
    }, {});
}
const transformData = (inputData) => {
    // Use Object.values to get an array of the nested objects
    const valuesArray = Object.values(inputData);

    // Reduce the array to a single object
    const transformedData = valuesArray.reduce((acc, curr) => {
        // Merge the current object into the accumulator
        return { ...acc, ...curr };
    }, {});

    return transformedData;
};
export default function OpCodeSearchForm({
    templateName,
    prevFrame = null,
    isUserStory = false,
    needCreate = false,
    isBulkCreate = false,
    filter = null,
    createModalTemplate = null,
    apiCalls
}) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [state, dispatch] = useReducer(searchReducer, initialSearchState);
    const {
        template,
        formData,
        tableData,
        currentPage,
        totalPage,
        totalFetchedRow,
        totalRows,
        prevFrameData,
    } = state;

    const [showSearchForm, setShowSearchForm] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const { GetPreviousContextData } = useContext(UserStoryContext);
    useEffect(() => {
        const getTemplate = async () => {
            if (prevFrame !== null && isUserStory === true) {
                console.log("pre contest", transformData(GetPreviousContextData()))
                const prevFrameDataTemp = transformData(GetPreviousContextData());
                if (prevFrameDataTemp !== null) {
                    dispatch({
                        type: "SET_FORM_DATA",
                        payload: prevFrameDataTemp,
                    });
                    dispatch({
                        type: "SET_PREVIOUS_CONTEXT_DATA",
                        payload: prevFrameDataTemp,
                    });
                }
            }

            let previousResponse = null
            let totalRowsPerPage = null
            let tableDisplayOrder = null

            if (apiCalls && apiCalls.length !== 0) {
                for (const { key, onLoad, onButton, server } of apiCalls) {
                    if (onLoad && onLoad === true) {
                        const config2 = apiConfig[key]
                        console.log("Configs ", config2)
                        let payload = {}
                        if (key === 'templateJson') {
                            previousResponse = await performApiRequest(
                                server,
                                key,
                                config2.url(templateName),
                                config2.method
                            );
                            if (previousResponse.data.length !== 0) {
                                dispatch({
                                    type: "SET_TEMPLATE",
                                    payload: previousResponse.data,
                                });
                                totalRowsPerPage =
                                    previousResponse.data.tableComponent.maxRowsPerPage;
                                tableDisplayOrder =
                                    previousResponse.data.tableComponent.resultDisplayOrder;
                            }
                        }
                        else {
                            let fdata
                            if (transformData(GetPreviousContextData()) && filter) {
                                fdata = createNewDictWithKeys(transformData(GetPreviousContextData()), filter);
                            } else {
                                fdata = formData;
                            }
                            const payLoad = config2.payloadFields(previousResponse.data, fdata, 1);
                            console.log("Search Payload", payLoad)
                            previousResponse = await performApiRequest(
                                server,
                                key,
                                config2.url,
                                config2.method,
                                payLoad
                            );
                            if (previousResponse.data.length !== 0) {
                                dispatch({
                                    type: "SET_TABLE_DATA",
                                    payload: previousResponse.data,
                                });
                                dispatch({
                                    type: "SET_TOTAL_PAGE",
                                    payload: Math.ceil(
                                        parseInt(
                                            previousResponse.data.totalRecords,
                                        ) / totalRowsPerPage,
                                    ),
                                });
                                if (tableDisplayOrder === "row") {
                                    dispatch({
                                        type: "SET_FETCHED_TOTAL_ROW",
                                        payload:
                                            previousResponse.data.tableRow
                                                .length,
                                    });
                                } else {
                                    dispatch({
                                        type: "SET_FETCHED_TOTAL_ROW",
                                        payload:
                                            previousResponse.data.tableCol[
                                                "recordId"
                                            ].length,
                                    });
                                }
                                dispatch({
                                    type: "SET_TOTAL_ROWS",
                                    payload: Math.ceil(
                                        parseInt(
                                            previousResponse.data.totalRecords,
                                        ),
                                    ),
                                });

                            } else {
                                dispatch({
                                    type: "SET_TABLE_DATA",
                                    payload: [],
                                });
                                dispatch({
                                    type: "SET_TOTAL_PAGE",
                                    payload: 0,
                                });
                                dispatch({
                                    type: "SET_FETCHED_TOTAL_ROW",
                                    payload: 0,
                                });
                                dispatch({
                                    type: "SET_TOTAL_ROWS",
                                    payload: 0,
                                });
                            }
                        }
                    }
                }
            }
            isFetching && setIsFetching(false);
        };

        getTemplate();

    }, [templateName]);


    const search = async (page, clear = false, fdata = {}) => {
        let payLoad = {};
        let previousResponse = null
        if (apiCalls && apiCalls.length !== 0) {

            for (const { key, onLoad, onButton, server } of apiCalls) {
                if (onButton && onButton === true) {

                    const config2 = apiConfig[key]
                    if (Object.keys(formData).length === 0 || clear === true) {
                        payLoad = config2.payloadFields(
                            template,
                            fdata,
                            (page - 1) * template.tableComponent.maxRowsPerPage,
                        );
                    } else {
                        payLoad = config2.payloadFields(
                            template,
                            formData,
                            (page - 1) * template.tableComponent.maxRowsPerPage,
                        );


                    }
                    previousResponse = await performApiRequest(
                        server,
                        key,
                        config2.url,
                        config2.method,
                        payLoad
                    );
                    if (!previousResponse.data.error) {
                        dispatch({
                            type: "SET_TABLE_DATA",
                            payload: previousResponse.data,
                        });
                        dispatch({
                            type: "SET_TOTAL_PAGE",
                            payload: Math.ceil(
                                parseInt(previousResponse.data.totalRecords) /
                                template.tableComponent.maxRowsPerPage,
                            ),
                        });
                        if (
                            template.tableComponent.resultDisplayOrder === "row"
                        ) {
                            dispatch({
                                type: "SET_FETCHED_TOTAL_ROW",
                                payload: previousResponse.data.tableRow.length,
                            });
                        } else {
                            dispatch({
                                type: "SET_FETCHED_TOTAL_ROW",
                                payload:
                                    previousResponse.data.tableCol["recordId"].length,
                            });
                        }
                        dispatch({
                            type: "SET_TOTAL_ROWS",
                            payload: Math.ceil(
                                parseInt(previousResponse.data.totalRecords),
                            ),
                        });
                        setIsFetching(false);
                    } else {
                        dispatch({
                            type: "SET_TABLE_DATA",
                            payload: [],
                        });
                        dispatch({
                            type: "SET_TOTAL_PAGE",
                            payload: 0,
                        });
                        dispatch({
                            type: "SET_FETCHED_TOTAL_ROW",
                            payload: 0,
                        });
                        dispatch({
                            type: "SET_TOTAL_ROWS",
                            payload: 0,
                        });
                    }

                }
            }
            setIsFetching(false);
        }
        setIsFetching(false);
    };

    const handleSubmit = async () => {
        dispatch({ type: "SET_CURRENT_PAGE", payload: 1 });
        await search(1);
    };

    const handleClear = async () => {
        dispatch({ type: "SET_CURRENT_PAGE", payload: 1 });
        dispatch({ type: "SET_FORM_DATA", payload: {} });
        if (prevFrame !== null && isUserStory === true) {
            dispatch({
                type: "SET_FORM_DATA",
                payload: prevFrameData,
            });
        }
        let fdata;
        if (transformData(GetPreviousContextData()) && filter) {

            fdata = createNewDictWithKeys(transformData(GetPreviousContextData()), filter);

        } else {
            fdata = {};
        }

        await search(1, true, fdata);
    };

    return template.tableName !== "" ? (
        <div>
            <div className="bg-white dark:bg-gray-950 rounded-lg shadow-sm">
                <div className="px-6 py-7">
                    <Collapsible className="transition-all duration-300 ease-in-out ">
                        <div className="flex space-y-0 space-x-10 ">
                            <CollapsibleTrigger asChild>
                                <Button
                                    className="w-15"
                                    variant="outline"
                                >
                                    <FilterIcon className="w-4 h-4 mr-2" />
                                    Filters
                                </Button>
                            </CollapsibleTrigger>
                            <CollapsibleContent className="w-full">


                                {template &&
                                    template.searchComponent &&
                                    template.searchComponent.fields &&
                                    template.searchComponent.fields.length > 0 && (
                                        <div className="flex flex-col items-center space-y-4">
                                            <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 w-100">
                                                <div className="flex justify-center space-x-4 ">
                                                    <SearchForm
                                                        formData={formData}
                                                        fields={template.searchComponent.fields}
                                                        windowWidth={windowWidth}
                                                        dispatch={dispatch}
                                                    />


                                                </div>
                                                <div className="flex justify-center space-x-4 ">
                                                    <PrimaryButton
                                                        onClick={() => {
                                                            handleSubmit();
                                                            setIsFetching(true);
                                                        }}
                                                        $text="Search"
                                                        disabled={false}
                                                    />
                                                    <PrimaryButton
                                                        $text="Clear Filter"
                                                        onClick={() => {
                                                            handleClear();
                                                            setIsFetching(true);
                                                        }}
                                                        disabled={false}
                                                        variant="destructive"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </CollapsibleContent>

                        </div>
                    </Collapsible>
                    {needCreate && (
                        <div className="flex justify-end p-4" Style="
    margin-top: -6%;
" >
                            <Button onClick={() => setShowModal((prev) => !prev)} style={{ borderRadius: "5px", width: "200px", marginLeft: "10px" }}>
                                <PlusIcon className="mr-2 h-4 w-4" />
                                Create
                            </Button>
                        </div>
                    )}

                </div>
            </div >
            <div>
                {needCreate && !createModalTemplate && (
                    <CreateSearchContainer>
                        {showModal && (
                            <Modal
                                $isOpen={showModal}
                                setOpenModal={setShowModal}>
                                <Modal.Header>
                                    Create Form - {templateName}
                                </Modal.Header>
                                <Modal.Body>
                                    {isBulkCreate === false ? (
                                        <OpCodeCreateForm
                                            templateName={templateName}
                                        />
                                    ) : (
                                        <OpCodeBulkCreateForm
                                            templateName={templateName}
                                        />
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "end",
                                        }}
                                    >
                                        <PrimaryButton
                                            $text="Close"
                                            onClick={() => {
                                                setShowModal(false);
                                            }}
                                            style={{ backgroundColor: "black" }}
                                        />
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        )}
                    </CreateSearchContainer>
                )}
            </div>

            {needCreate && createModalTemplate && (
                <Modal
                    $isOpen={showModal}
                    setOpenModal={setShowModal}>
                    <Modal.Header>OP Code Create Form</Modal.Header>
                    <Modal.Body>
                        <OpCodeCreateForm
                            templateName={createModalTemplate.frameTemplateName}
                            prevFrame={createModalTemplate.previousFrame}
                            isUserStory={true}
                            filter={createModalTemplate.filter}
                            apiCalls={createModalTemplate.apiCalls}
                        />
                    </Modal.Body>
                </Modal>
            )}
            <div>


            </div>

            <div className="">
                {tableData && (
                    <Card2
                        body={
                            <OpCodeTable
                                isFetching={isFetching}
                                templateName={templateName}
                                OpCodeEditTemplateName={
                                    template.OpCodeEditTemplateName
                                }
                                tableName={template.tableName}
                                tableCol={template.tableComponent.tableCol}
                                tableData={tableData}
                                totalFetchedRow={totalFetchedRow}
                                haveSelect={isUserStory ? true : false}
                                dispatch={dispatch}
                                tableDisplayOrder={
                                    template.tableComponent
                                        .resultDisplayOrder
                                }
                            />
                        }
                        footer={[
                            tableData && totalFetchedRow > 0 && (
                                <PaginationContainer >
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPage}
                                        totalRows={totalRows}
                                        maxNumberOfRows={
                                            template.tableComponent
                                                .maxRowsPerPage
                                        }
                                        totalFetchedRow={totalFetchedRow}
                                        onPageChange={(page) => {
                                            dispatch({
                                                type: "SET_CURRENT_PAGE",
                                                payload: page,
                                            });
                                            search(page);
                                        }}
                                        setFetching={setIsFetching}
                                    />
                                </PaginationContainer>
                            ),
                        ]}
                        footerCenter={false}

                    />

                )}
                <div className="flex items-center space-x-4">
                    <DownloadCSV jsonData={tableData.tableRow} fileName="data.csv" />
                </div>
            </div>
            {/* {
            showModal &&
            <Modal open={showModal} title='OpCode Create' onClose={() => setShowModal((prev) => !prev)}>
              {
                template.formComponent?.isBulk === true? 
                  <OpCodeBulkCreate templateName={templateName} className="app" />
                :
                  <OpCodeCreate templateName={templateName} className="app" />
              }
            </Modal>
          } */}
        </div >
    ) : (
        <div>
            <AppLoader />
        </div>
    );
}

function FilterIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
        </svg>
    );
}

function PlusIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="5" y1="12" x2="19" y2="12" />
        </svg>
    );
}