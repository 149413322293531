// import React, { useState, useEffect, useRef } from 'react';
// import io from 'socket.io-client';

// const ChatPage = () => {
//     const [userMessage, setUserMessage] = useState('');
//     const [messages, setMessages] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const chatBodyRef = useRef(null);

//     // Unique user and conversation IDs
//     const userId = useRef(localStorage.getItem('user_id') || `user-${Math.random().toString(36).substr(2, 9)}`);
//     const conversationId = useRef(sessionStorage.getItem('conversation_id') || `conv-${Date.now()}-${Math.random().toString(36).substr(2)}`);
//     const socket = useRef(null);

//     useEffect(() => {
//         // Initialize socket connection
//         socket.current = io('https://stgapp.coolriots.ai', { path: "/feo-v3/api/socketio" });

//         // Store user and conversation IDs
//         localStorage.setItem('user_id', userId.current);
//         sessionStorage.setItem('conversation_id', conversationId.current);

//         // Listen for bot responses
//         socket.current.on("message", (data) => {
//             setLoading(false);  // Stop loading animation
//             setMessages(prevMessages => [...prevMessages, { text: data, sender: "bot" }]);
//         });

//         return () => socket.current.disconnect();
//     }, []);

//     const handleSendMessage = () => {
//         if (userMessage.trim() === '') return;

//         // Append user message to chat
//         setMessages(prevMessages => [...prevMessages, { text: userMessage, sender: "user" }]);
        
//         // Send message to server
//         socket.current.emit("message", {
//             message: userMessage,
//             user_id: userId.current,
//             conversation_id: conversationId.current,
//             base_url: window.location.origin
//         });

//         // Clear input and set loading state
//         setUserMessage('');
//         setLoading(true);
//     };

//     // Auto-scroll to the bottom when a new message is added
//     useEffect(() => {
//         chatBodyRef.current?.scrollTo(0, chatBodyRef.current.scrollHeight);
//     }, [messages]);

//     return (
//         <div style={styles.chatPage}>
//             <div style={styles.chatHeader}>
//                 <h3>BeX AI Assistant</h3>
//             </div>

//             <div style={styles.chatBody} ref={chatBodyRef}>
//                 {messages.map((msg, index) => (
//                     <div key={index} style={{ ...styles.chatMessage, ...styles[msg.sender] }}>
//                         <span>{msg.text}</span>
//                     </div>
//                 ))}
//                 {loading && (
//                     <div style={{ ...styles.chatMessage, ...styles.bot, ...styles.typingLoader }}>
//                         <div style={styles.loaderDot}></div>
//                         <div style={styles.loaderDot}></div>
//                         <div style={styles.loaderDot}></div>
//                     </div>
//                 )}
//             </div>

//             <div style={styles.chatFooter}>
//                 <textarea
//                     rows="1"
//                     placeholder="Type something..."
//                     value={userMessage}
//                     onChange={(e) => setUserMessage(e.target.value)}
//                     onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && handleSendMessage()}
//                     style={styles.textArea}
//                 />
//                 <button onClick={handleSendMessage} style={styles.sendButton}>
//                     <i className="fas fa-paper-plane"></i>
//                 </button>
//             </div>
//         </div>
//     );
// };

// // Styles in the same file
// const styles = {
//     chatPage: {
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100vh',
//         width: '100%',
//         fontFamily: 'Poppins, sans-serif',
//     },
//     chatHeader: {
//         backgroundColor: '#b1092a',
//         color: 'white',
//         padding: '15px',
//         textAlign: 'center',
//     },
//     chatBody: {
//         flex: 1,
//         padding: '15px',
//         overflowY: 'auto',
//         backgroundColor: '#f4f4f4',
//     },
//     chatMessage: {
//         display: 'flex',
//         padding: '10px',
//         margin: '5px 0',
//         maxWidth: '70%',
//         wordWrap: 'break-word',
//         borderRadius: '8px',
//     },
//     user: {
//         alignSelf: 'flex-end',
//         backgroundColor: '#dfe8e3',
//     },
//     bot: {
//         alignSelf: 'flex-start',
//         backgroundColor: '#dee6ec',
//     },
//     chatFooter: {
//         display: 'flex',
//         padding: '10px',
//         borderTop: '1px solid #ccc',
//         backgroundColor: 'white',
//     },
//     textArea: {
//         flex: 1,
//         padding: '10px',
//         border: 'none',
//         outline: 'none',
//         borderRadius: '8px',
//         resize: 'none',
//         fontSize: '14px',
//     },
//     sendButton: {
//         marginLeft: '10px',
//         backgroundColor: '#b1092a',
//         color: 'white',
//         border: 'none',
//         padding: '10px 15px',
//         borderRadius: '8px',
//         cursor: 'pointer',
//         display: 'flex',
//         alignItems: 'center',
//     },
//     typingLoader: {
//         display: 'flex',
//         marginLeft: '10px',
//     },
//     loaderDot: {
//         width: '8px',
//         height: '8px',
//         backgroundColor: '#b1092a',
//         margin: '0 3px',
//         borderRadius: '50%',
//         animation: 'bounce 1.5s infinite ease-in-out',
//     },
// };

// export default ChatPage;



'use client'

import { useState, useEffect } from 'react'
import { SendIcon } from 'lucide-react'
import { Button } from "src/components/ui/button"
import { Input } from "src/components/ui/input"
import { Card } from "src/components/ui/card"

// Replace with your actual API endpoint
const API_URL = 'https://your-api-endpoint.com/chat';

export default function ChatPage() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setShowAnimation(false);
    }
  }, [isLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim() === '') return;

    const userMessage = { id: Date.now(), role: 'user', content: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput('');
    setIsLoading(true);
    setShowAnimation(true);

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: input }),
      });
      const data = await response.json();
      const botMessage = { id: Date.now() + 1, role: 'bot', content: data.reply };

      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Error fetching response:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: Date.now() + 2, role: 'bot', content: 'Error: Unable to fetch response.' }
      ]);
    } finally {
      setIsLoading(false);
      setShowAnimation(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="flex flex-col h-[600px] w-full max-w-6xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
        <Card className="flex-grow overflow-auto mb-4 p-6">
          {messages.map((message) => (
            <div
              key={message.id}
              className={`mb-4 ${
                message.role === 'user' ? 'text-right' : 'text-left'
              }`}
            >
              <span
                className={`inline-block p-3 rounded-lg ${
                  message.role === 'user'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-800'
                }`}
              >
                {message.content}
              </span>
            </div>
          ))}
          {showAnimation && (
            <div className="text-left mb-4">
              <span className="inline-block p-3 rounded-lg bg-gray-200 text-gray-800">
                <LoadingAnimation />
              </span>
            </div>
          )}
        </Card>
        <div className="p-4 bg-gray-50">
          <form onSubmit={handleSubmit} className="flex gap-2">
            <Input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type your message..."
              className="flex-grow"
            />
            <Button type="submit" disabled={isLoading}>
              <SendIcon className="h-4 w-4 mr-2" />
              Send
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}

function LoadingAnimation() {
  return (
    <span className="inline-flex items-center">
      <span className="animate-pulse">.</span>
      <span className="animate-pulse animation-delay-200">.</span>
      <span className="animate-pulse animation-delay-400">.</span>
    </span>
  )
}




/*
'use client'

import { useState, useEffect } from 'react'
import { SendIcon } from 'lucide-react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Card } from "@/components/ui/card"

const WEBSOCKET_URL = 'wss://your-websocket-server.com/chat'; // Replace with your WebSocket URL

export default function ChatPage() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Establish WebSocket connection
    const ws = new WebSocket(WEBSOCKET_URL);
    setSocket(ws);

    // Handle incoming messages from WebSocket
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const botMessage = { id: Date.now(), role: 'bot', content: data.reply };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setIsLoading(false);
      setShowAnimation(false);
    };

    // Cleanup WebSocket connection on component unmount
    return () => {
      ws.close();
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!input.trim() || !socket || socket.readyState !== WebSocket.OPEN) return;

    const userMessage = { id: Date.now(), role: 'user', content: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput('');
    setIsLoading(true);
    setShowAnimation(true);

    // Send the user's message through WebSocket
    socket.send(JSON.stringify({ message: input }));
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="flex flex-col h-[600px] w-full max-w-6xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
        <Card className="flex-grow overflow-auto mb-4 p-6">
          {messages.map((message) => (
            <div
              key={message.id}
              className={`mb-4 ${
                message.role === 'user' ? 'text-right' : 'text-left'
              }`}
            >
              <span
                className={`inline-block p-3 rounded-lg ${
                  message.role === 'user'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-800'
                }`}
              >
                {message.content}
              </span>
            </div>
          ))}
          {showAnimation && (
            <div className="text-left mb-4">
              <span className="inline-block p-3 rounded-lg bg-gray-200 text-gray-800">
                <LoadingAnimation />
              </span>
            </div>
          )}
        </Card>
        <div className="p-4 bg-gray-50">
          <form onSubmit={handleSubmit} className="flex gap-2">
            <Input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type your message..."
              className="flex-grow"
            />
            <Button type="submit" disabled={isLoading}>
              <SendIcon className="h-4 w-4 mr-2" />
              Send
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}

function LoadingAnimation() {
  return (
    <span className="inline-flex items-center">
      <span className="animate-pulse">.</span>
      <span className="animate-pulse animation-delay-200">.</span>
      <span className="animate-pulse animation-delay-400">.</span>
    </span>
  )
}
*/