export const apiConfig = { 
    "templateJson":
    {
        url: (templateName) =>{ 
            const url = `/api/template/${templateName}`
            return url
        },
        method: 'get',
        errorMessage: 'Failed to load Template'
    },
    "bexInsights" : 
    {
        url: '/bexinsights/search',
        method: 'post',
        payloadFields: (templateData, formData, page = 1) => {
            page = page === 0 ? 1 : page;

            if (!templateData?.tableName ||
                !templateData.searchComponent.fields.length ||
                !templateData.tableComponent.tableCol) {
                return {}; // Early return if essential data is missing
            }

            const rules = formData && Object.keys(formData).length > 0
                ? templateData.searchComponent.fields.map(field => {
                    console.log("debug2",field.field)
                     if (!formData[field.field]) {
                       
                        return undefined; // Skip fields without formData
                    }
                    return {
                        dataType: field.dataType,
                        field: field.foreignTable ? undefined : field.field.endsWith('_recid') 
                        ? field.field.slice(0, -'_recid'.length) 
                        : field.field,
                        foreignTable: field.foreignTable,
                        cfield: field.foreignTable ? field.cfield : undefined,
                        foreignDataType: field.foreignTable ? field.foreignDataType : undefined,
                        foreignField: field.foreignField,
                        date_from: field.date_from ? formData[field.field] : undefined,
                        date_to: field.date_to ? formData[field.field] : undefined,
                        comparisonOperator: !field.date_from && !field.date_to ? field.comparisonOperator : undefined,
                        value: !field.date_from && !field.date_to ? formData[field.field] : undefined,
                        // foreignRecordIdDataType: field.foreignTable ? field.foreignRecordIdDataType : undefined
                    };
                }).filter(rule => rule !== undefined)
                : [{
                    field: "recordId",
                    dataType: "tag",
                    comparisonOperator: "notEquals",
                    value: "null",
                }];
               // Ensure that 'rules' contains at least one rule
if (rules.length === 0) {
    rules.push({
        field: "recordId",
        dataType: "tag",
        comparisonOperator: "notEquals",
        value: "null",
    });
}
            const columnsToRetrieve = templateData.tableComponent.tableCol.map(item => ({
                field: item.foreignTable ? undefined : item.field,
                dataType: item.dataType,
                cfield: item.foreignTable ? item.cfield : undefined,
                keyName: item.foreignTable ? item.keyName : undefined,
                foreignTable: item.foreignTable,
                foreignField: item.foreignField,
                foreignRecordIdDataType: item.foreignTable ? item.foreignRecordIdDataType : undefined
            }));
            console.log("rules", rules);
            console.log("columnsToRetrieve", columnsToRetrieve);
            return {
                target: templateData.tableName,
                action: "search",
                filters: {
                    logicalOperator: templateData.searchComponent.logicalOperator,
                    rules: rules,
                },
                columnsToRetrieve: columnsToRetrieve,
                resultDisplayOrder: templateData.tableComponent.resultDisplayOrder,
                maxRowsPerPage: templateData.tableComponent.maxRowsPerPage,
                pageToRetrieve: page,
            };
        }
    },
    "bexAction" : 
    {
        url: '/bexo/actions/bex-action',
        method: 'post',
        payloadFields: (data, tableName,operation) => {
            if (
                data &&
                tableName &&
                Object.keys(data).length > 0 &&
                tableName !== ""
            ) {
console.log("apiCalls",operation)
                if(operation==="create"){
                const payLoad = {
                    Meta: {
                        Child: {
                            Name: tableName,
                            Type: "Child Create",
                        },
                        Action: "create",
                    },
                    ChildRecords: [data],
                };
                return payLoad;
            }
            else if(operation==="update"){
                const payLoad = {
                    Meta: {
                        Child: {
                            Name: tableName,
                           Type: "Child Search"
                        },
                        Action: "update",
                    },
                    ChildRecords: [data],
                };
                return payLoad;
            }
            else if(operation==="delete"){
                const payLoad = {
                    Meta: {
                        Child: {
                            Name: tableName,
                           Type: "Child Search"
                        },
                        Action: "delete",
                    },
                    ChildRecords: [data],
                };
                return payLoad;
            }
        }
        },
        successMessage: 'Record Created Successfully',
        errorMessage: 'Record Creation Failed'
    },
    "bookingRegAPI" : 
    {
        url: '/bexo/create/open-booking-registration',
        method: 'post',
        payloadFields: (data) => {
            if (
                data &&
                Object.keys(data).length > 0
            ) {
                const payLoad = data;
                return payLoad;
            }
        },
        successMessage: 'Record Created Successfully',
        errorMessage: 'Record Creation Failed'
    },
};