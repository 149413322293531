import { useState, useRef, useEffect } from 'react'
import { Link } from "react-router-dom"; // Import useNavigate
import { Button } from "./ui/button"
import { ScrollArea } from "./ui/scroll-area"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "./ui/dropdown-menu"
import { Textarea } from "./ui/textarea"
import { Input } from "./ui/input"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog"
import {
    Bot,
    ChevronDown,
    Menu,
    Send,
    User,
    X,
    Trash2,
    Plus,
    Settings,
    HelpCircle,
    MessageSquare,
    Edit2,
    Check,
} from "lucide-react"

export default function EnhancedEditableChatInterface() {

    const [conversations, setConversations] = useState([
        {
            id: '1',
            title: 'Support Chat',
            messages: [
                { id: '1', role: 'assistant', content: 'Hello! How can I assist you today?' },
                { id: '2', role: 'user', content: 'I need help with my order.' },
                { id: '3', role: 'assistant', content: 'Sure! Can you please provide the order ID?' },
            ],
            createdAt: new Date(Date.now() - 1000 * 60 * 60 * 24), // 1 day ago
        },
        {
            id: '2',
            title: 'Project Discussion',
            messages: [
                { id: '1', role: 'user', content: 'Let’s discuss the new project roadmap.' },
                { id: '2', role: 'assistant', content: 'Absolutely! What do you want to focus on first?' },
            ],
            createdAt: new Date(Date.now() - 1000 * 60 * 60 * 24 * 2), // 2 days ago
        },
        {
            id: '3',
            title: 'Casual Chat',
            messages: [
                { id: '1', role: 'user', content: 'Hey, how’s the weather today?' },
                { id: '2', role: 'assistant', content: 'It’s sunny with clear skies today.' },
            ],
            createdAt: new Date(Date.now() - 1000 * 60 * 60 * 24 * 7), // 7 days ago
        },
    ])
    const [currentConversationId, setCurrentConversationId] = useState('1')
    const [inputMessage, setInputMessage] = useState('')
    const [editingMessageId, setEditingMessageId] = useState(null)
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const [isComposing, setIsComposing] = useState(false)
    const [aiModel, setAiModel] = useState('')
    const [isSettingsOpen, setIsSettingsOpen] = useState(false)
    const [isHelpOpen, setIsHelpOpen] = useState(false)
    const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false)
    const [newTitle, setNewTitle] = useState('')
    const messagesEndRef = useRef(null)

    const currentConversation = conversations.find(conv => conv.id === currentConversationId) || conversations[0] || {
        id: '0',
        title: 'No Conversations',
        messages: [],
        createdAt: new Date(),
    }

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [currentConversation.messages])

    const handleSendMessage = () => {
        if (inputMessage.trim() && conversations.length > 0) {
            const newMessage = {
                id: Date.now().toString(),
                role: 'user',
                content: inputMessage.trim(),
            }
            const updatedConversations = conversations.map(conv =>
                conv.id === currentConversationId
                    ? {
                        ...conv,
                        messages: [...conv.messages, newMessage],
                    }
                    : conv
            )
            setConversations(updatedConversations)
            setInputMessage('')
            // Simulate AI response
            setTimeout(() => {
                const aiResponse = {
                    id: (Date.now() + 1).toString(),
                    role: 'assistant',
                    content: `I'm an AI assistant using ${aiModel}. I received your message: "${newMessage.content}"`,
                }
                const updatedWithAiResponse = updatedConversations.map(conv =>
                    conv.id === currentConversationId
                        ? {
                            ...conv,
                            messages: [...conv.messages, aiResponse],
                        }
                        : conv
                )
                setConversations(updatedWithAiResponse)
            }, 1000)
        }
    }

    const handleNewConversation = () => {
        const newConversation = {
            id: Date.now().toString(),
            title: 'New Chat',
            messages: [{ id: Date.now().toString(), role: 'assistant', content: `Hello! I'm an AI assistant using ${aiModel}. How can I help you?` }],
            createdAt: new Date(),
        }
        setConversations([newConversation, ...conversations])
        setCurrentConversationId(newConversation.id)
    }

    const handleDeleteConversation = (id) => {
        const updatedConversations = conversations.filter(conv => conv.id !== id)
        setConversations(updatedConversations)
        if (id === currentConversationId) {
            setCurrentConversationId(updatedConversations[0]?.id || '0')
        }
    }

    const handleRenameConversation = () => {
        if (newTitle.trim()) {
            const updatedConversations = conversations.map(conv =>
                conv.id === currentConversationId
                    ? {
                        ...conv,
                        title: newTitle.trim(),
                    }
                    : conv
            )
            setConversations(updatedConversations)
            setIsRenameDialogOpen(false)
            setNewTitle('')
        }
    }

    const handleEditMessage = (messageId) => {
        setEditingMessageId(messageId)
    }

    const handleSaveEditedMessage = (messageId, newContent) => {
        const updatedConversations = conversations.map(conv =>
            conv.id === currentConversationId
                ? {
                    ...conv,
                    messages: conv.messages.map(msg =>
                        msg.id === messageId
                            ? { ...msg, content: newContent }
                            : msg
                    ),
                }
                : conv
        )
        setConversations(updatedConversations)
        setEditingMessageId(null)
    }

    const groupConversations = () => {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        const lastWeek = new Date(today)
        lastWeek.setDate(lastWeek.getDate() - 7)
        const lastMonth = new Date(today)
        lastMonth.setDate(lastMonth.getDate() - 30)

        return {
            today: conversations.filter(conv => conv.createdAt >= today),
            yesterday: conversations.filter(conv => conv.createdAt >= yesterday && conv.createdAt < today),
            lastWeek: conversations.filter(conv => conv.createdAt >= lastWeek && conv.createdAt < yesterday),
            lastMonth: conversations.filter(conv => conv.createdAt >= lastMonth && conv.createdAt < lastWeek),
            older: conversations.filter(conv => conv.createdAt < lastMonth),
        }
    }

    const groupedConversations = groupConversations()

    const renderConversationGroup = (title, convs) => (
        convs.length > 0 && (
            <div key={title}>
                <h3 className="px-2 py-1 text-xs font-semibold text-gray-500">{title}</h3>
                {convs.map((conv) => (
                    <div key={conv.id} className="flex items-center group mb-1">
                        <Button
                            variant={conv.id === currentConversationId ? "secondary" : "ghost"}
                            className="w-full justify-between items-center"
                            onClick={() => setCurrentConversationId(conv.id)}
                        >
                            <div className="flex items-center">
                                <MessageSquare className="mr-2 h-4 w-4" />
                                <span className="truncate">{conv.title}</span>
                            </div>
                            <div className="flex items-center">
                                <Button
                                    variant="ghost"
                                    size="icon"
                                    className="h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setNewTitle(conv.title)
                                        setIsRenameDialogOpen(true)
                                    }}
                                >
                                    <Edit2 className="h-4 w-4" />
                                </Button>
                                <Button
                                    variant="ghost"
                                    size="icon"
                                    className="h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleDeleteConversation(conv.id)
                                    }}
                                >
                                    <Trash2 className="h-4 w-4" />
                                </Button>
                            </div>
                        </Button>
                    </div>
                ))}
            </div>
        )
    )

    return (
        <div className="flex h-screen bg-gray-100">
            {/* Sidebar */}
            <div
                className={`fixed inset-y-0 left-0 z-50 w-64 bg-gray-900 text-white transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
                    } transition-transform duration-300 ease-in-out md:relative md:translate-x-0`}
            >
                <div className="flex flex-col items-center justify-between p-4 border-b border-gray-700">
                    <Link
                        to="/user-story/training/ca?templateName=dashboard"
                        className="text-blue-500 underline"
                    >
                        Go Back
                    </Link>
                    <h2 className="text-xl font-semibold">Chat History</h2>
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => setIsSidebarOpen(false)}
                        className="md:hidden"
                    >
                        <X className="h-6 w-6" />
                    </Button>
                </div>


                <ScrollArea className="h-[calc(100vh-64px)]">
                    <div className="p-4 space-y-2">
                        <div className="flex items-center group mb-1">
                            <Button
                                variant="outline"
                                className="w-full justify-between items-center bg-gray-800 text-white hover:bg-gray-700 hover:text-white"
                                onClick={handleNewConversation}
                            >
                                <div className="flex items-center">
                                    <Plus className="mr-2 h-4 w-4" />
                                    <span>New Chat</span>
                                </div>
                                <div className="flex items-center">
                                    <Button
                                        variant="ghost"
                                        size="icon"
                                        className="h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            // Add functionality for editing new chat if needed
                                        }}
                                    >
                                        <Edit2 className="h-4 w-4" />
                                    </Button>
                                    <Button
                                        variant="ghost"
                                        size="icon"
                                        className="h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            // Add functionality for deleting new chat if needed
                                        }}
                                    >
                                        <Trash2 className="h-4 w-4" />
                                    </Button>
                                </div>
                            </Button>
                        </div>
                        {renderConversationGroup("Today", groupedConversations.today)}
                        {renderConversationGroup("Yesterday", groupedConversations.yesterday)}
                        {renderConversationGroup("Previous 7 Days", groupedConversations.lastWeek)}
                        {renderConversationGroup("Previous 30 Days", groupedConversations.lastMonth)}
                        {renderConversationGroup("Older", groupedConversations.older)}
                    </div>
                </ScrollArea>
                <div className="absolute bottom-0 left-0 right-0 p-4 border-t border-gray-700">
                    <Button variant="ghost" className="w-full justify-start" onClick={() => setIsSettingsOpen(true)}>
                        <Settings className="mr-2 h-4 w-4" />
                        Settings
                    </Button>
                    <Button variant="ghost" className="w-full justify-start" onClick={() => setIsHelpOpen(true)}>
                        <HelpCircle className="mr-2 h-4 w-4" />
                        Help & FAQ
                    </Button>
                </div>
            </div>

            {/* Main Content */}
            <div className="flex-1 flex flex-col">
                {/* Header */}
                <header className="bg-white border-b p-4 flex items-center justify-between">
                    {/* <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => setIsSidebarOpen(true)}
                        className="md:hidden"
                    >
                        <Menu className="h-6 w-6" />
                    </Button>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline">
                                {aiModel} <ChevronDown className="ml-2 h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
              <DropdownMenuItem onClick={() => setAiModel('GPT-3.5')}>GPT-3.5</DropdownMenuItem>
              <DropdownMenuItem onClick={() => setAiModel('GPT-4')}>GPT-4</DropdownMenuItem>
            </DropdownMenuContent>
                    </DropdownMenu> */}
                </header>

                {/* Chat Area */}
                <ScrollArea className="flex-1 p-4">
                    <div className="max-w-3xl mx-auto space-y-4">
                        {currentConversation.messages.map((message) => (
                            <div
                                key={message.id}
                                className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'
                                    }`}
                            >
                                <div
                                    className={`flex items-start space-x-2 ${message.role === 'user' ? 'flex-row-reverse space-x-reverse' : ''
                                        }`}
                                >
                                    <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center">
                                        {message.role === 'user' ? (
                                            <User className="h-5 w-5 text-gray-600" />
                                        ) : (
                                            <Bot className="h-5 w-5 text-gray-600" />
                                        )}
                                    </div>
                                    <div
                                        className={`max-w-md p-3 rounded-lg ${message.role === 'user'
                                            ? 'bg-blue-500 text-white'
                                            : 'bg-gray-200 text-gray-800'
                                            }`}
                                    >
                                        {editingMessageId === message.id ? (
                                            <div className="flex items-center space-x-2">
                                                <Input
                                                    defaultValue={message.content}
                                                    className="flex-grow"
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleSaveEditedMessage(message.id, e.currentTarget.value)
                                                        }
                                                    }}
                                                />
                                                <Button
                                                    size="icon"
                                                    variant="ghost"
                                                    onClick={() => handleSaveEditedMessage(message.id, message.content)}
                                                >
                                                    <Check className="h-4 w-4" />
                                                </Button>
                                            </div>
                                        ) : (
                                            <div className="flex items-center space-x-2">
                                                <span>{message.content}</span>
                                                {message.role === 'user' && (
                                                    <Button
                                                        size="icon"
                                                        variant="ghost"
                                                        className="opacity-0 group-hover:opacity-100"
                                                        onClick={() => handleEditMessage(message.id)}
                                                    >
                                                        <Edit2 className="h-4 w-4" />
                                                    </Button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                </ScrollArea>

                {/* Input Area */}
                <div className="border-t p-4">
                    <div className="max-w-3xl mx-auto flex items-end space-x-2">
                        <Textarea
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            onCompositionStart={() => setIsComposing(true)}
                            onCompositionEnd={() => setIsComposing(false)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey && !isComposing) {
                                    e.preventDefault()
                                    handleSendMessage()
                                }
                            }}
                            placeholder="Type your message here..."
                            className="flex-1"
                            rows={1}
                        />
                        <Button onClick={handleSendMessage} disabled={!inputMessage.trim() || conversations.length === 0}>
                            <Send className="h-4 w-4" />
                            <span className="sr-only">Send message</span>
                        </Button>
                    </div>
                </div>
            </div>

            {/* Settings Dialog */}
            <Dialog open={isSettingsOpen} onOpenChange={setIsSettingsOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Settings</DialogTitle>
                    </DialogHeader>
                    {/* <div className="py-4">
                        <h3 className="font-medium mb-2">AI Model</h3>
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="outline">
                                    {aiModel} <ChevronDown className="ml-2 h-4 w-4" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuItem onClick={() => setAiModel('GPT-3.5')}>GPT-3.5</DropdownMenuItem>
                                <DropdownMenuItem onClick={() => setAiModel('GPT-4')}>GPT-4</DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div> */}
                </DialogContent>
            </Dialog>

            {/* Help Dialog */}
            <Dialog open={isHelpOpen} onOpenChange={setIsHelpOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Help & FAQ</DialogTitle>
                    </DialogHeader>
                    <div className="py-4">
                        <h3 className="font-medium mb-2">How to use the chat interface:</h3>
                        <ul className="list-disc pl-5 space-y-2">
                            <li>Type your message in the input field at the bottom of the screen.</li>
                            <li>Press Enter or click the Send button to send your message.</li>
                            <li>Use the sidebar to switch between conversations or start a new one.</li>
                            <li>You can rename or delete conversations using the buttons next to each conversation.</li>
                            <li>Change the AI model using the dropdown menu in the top right corner.</li>
                            <li>To edit a message you've sent, hover over it and click the edit icon.</li>
                        </ul>
                    </div>
                </DialogContent>
            </Dialog>

            {/* Rename Dialog */}
            <Dialog open={isRenameDialogOpen} onOpenChange={setIsRenameDialogOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Rename Conversation</DialogTitle>
                    </DialogHeader>
                    <div className="py-4">
                        <Input
                            value={newTitle}
                            onChange={(e) => setNewTitle(e.target.value)}
                            placeholder="Enter new title"
                        />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <Button variant="outline" onClick={() => setIsRenameDialogOpen(false)}>Cancel</Button>
                        <Button onClick={handleRenameConversation}>Rename</Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}